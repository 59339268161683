import React, { useState , useEffect } from 'react';
import '../assets/styles/ResponsiveContainer.css';
import {fetchAllUsers,fetchDevices} from '../logic/fetchUsers';
import io from 'socket.io-client';
import { URL } from '../logic/consts';

const socket = io(`${URL}`,{
    withCredentials: true,
});
const Analytics = () => {
    const [activeUsers, setActiveUsers] = useState('');
    const [loading, setLoading] = useState(true);
    const [dots, setDots] = useState('');

    const [allUsers, setAllUsers] = useState(null);
    const [maxDeviceList, setMaxDeviceList] = useState(null);

    socket.on('activeUser', (data) => {        
        const userString = data.toString()
        setLoading(false);
        setActiveUsers(userString);
    });
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        

        fetchAllUsers(storedToken)
        .then(data => {
            setAllUsers(data);
        })
        .catch(error => {
          // Handle the error
          console.log('Error:', error);
        });

        fetchDevices()
        .then(data => {
            setMaxDeviceList(data);
        })
        .catch(error => {
          // Handle the error
          console.log('Error:', error);
        });

        const intervalId = setInterval(() => {
            setDots((prevDots) => (prevDots.length >= 7 ? '' : prevDots + ' .'));
        }, 90); // Adjust the interval as needed

        return () => {
            clearInterval(intervalId);
        };

        
    }, []);


    const inlineStyles ={
        form:{
            zIndex:-1
        }
      }
    return (
        <div className="responsive-container">
            <div className="data-post-container">
                <div className="stats">
                    <div className="stat">
                        <h3>Active</h3>
                        <p><b>{loading ? `.${dots}` :activeUsers}</b></p>
                    </div>
                    <div className="stat">
                        <h3>Today Max</h3>
                        <p><b>{allUsers && allUsers.userCounts ? (allUsers.userCounts[0].count):(`${dots}`)}</b></p>
                    </div>

                    <div className="stat">
                        <h3>Today Device Counts</h3>
                        <p><b>{maxDeviceList ? (maxDeviceList[0].device_count):(`${dots}`)}</b></p>
                    </div>
                </div>  
                
                <h3>Last 10 Days</h3>   
                {allUsers && allUsers.userCounts ? (
                    allUsers.userCounts.map((userCount, index) => (
                        <div key={index}>
                        <p>{userCount.date} - <b>{userCount.count}</b></p>
                        <hr />
                        </div>
                    ))
                    ) : (
                    <p>No user counts available</p>
                    )}
                <form style={inlineStyles.form}>
                    <div style={inlineStyles.form} className="form-group">
                        <label style={inlineStyles.form} htmlFor="data">Enter Data</label>
                        <textarea style={inlineStyles.form}
                        rows={20}
                        cols={40}
                        ></textarea>
                    </div>
                </form> 
            </div>
        </div>
    );
};

export default Analytics;
