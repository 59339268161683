import axios from 'axios';
import { URL } from './consts';

export const switchPostData = async (data, token, setError, setSuccess) => {
    try {
        const response = await axios.post(
            `${URL}/setSwitchState`, 
            {switchState: !data },
            {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${token}`,
            },
            
        });
        const { success, message } = response.data;
        if (success) {
          setError(null);
          setSuccess(message);
          setTimeout(() => {
            setSuccess(null);
          }, 2000);
        } else {
          setError(message);
          setSuccess(null);
          setTimeout(() => {
            setError(null);
          }, 2000);
        }
    } catch (error) {
      setError('Data posting failed');
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };
  

export const switchGetData = async (token) => {
    try {
      const response = await axios.get(`${URL}/getSwitchState`, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        },
      });
  
      const data = response.data.switchState;
      return data;
    } catch (error) {
      console.error('Error loading text file:', error);
      throw error; 
    }
  };

