import axios from 'axios';
import { URL } from './consts';

export const getIntroData = async (token) => {
    try {
      const response = await axios.get(`${URL}/intro`, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        },
      });
  
      
      return response;
    } catch (error) {
      console.error('Error loading json file:', error);
      throw error; 
    }
  };

export const postIntroData = async (data, token, setError, setSuccess) => {
    try {
        const response = await axios.post(
            `${URL}/intro`, 
            data,
            {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${token}`,
            },
            
        });
        const { success, message } = response.data;
        if (success) {
        setError(null);
        setSuccess(message);
        setTimeout(() => {
            setSuccess(null);
        }, 2000);
        } else {
        setError(message);
        setSuccess(null);
        setTimeout(() => {
            setError(null);
        }, 2000);
        }
    } catch (error) {
    setError('Data posting failed');
    setSuccess(null);
    setTimeout(() => {
        setError(null);
    }, 2000);
    }
};
      