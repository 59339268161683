// Login component (Login.js)
import React, { useState } from 'react';
import axios from 'axios';
import '../assets/styles/LoginForm.css';
import '../assets/styles/ResponsiveContainer.css';
import { URL } from '../logic/consts';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleLogin = async () => {
    setLoading(true);
    try {
      // Send login credentials to the backend
      const response = await axios.post(`${URL}/auth/login`, {
        username,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const { token,error,message } = response.data;
      if(!error){
        localStorage.setItem('token', token);
        onLogin(token);
        setError(null);
        setLoading(false)
      }else{
        setError(message);
        setLoading(false)
        setTimeout(() => {
          setError(null)
        }, 2000);
      }
      
    } catch (error) {
      console.error('Login failed', error);
      setLoading(false)
      setError('An error occurred during login');
      setTimeout(() => {
        setError(null)
      }, 2000);
    }
  };

  return (
    <div className="login-container">
      <div>
        <nav className="navbar">
        </nav>
    </div>
    <h2>Login</h2>
    {error && <div className="error-message">{error}</div>}
    <div className={loading ? 'loadingSpinner' : 'hiddenSpinner'}>
        <div className="spinner"></div>
      </div>
    <form>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          placeholder="Enter your username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="button" onClick={handleLogin}>
        {loading ? "Authenticating..." : "Login"}
      </button>
    </form>
  </div>
  );
};

export default Login;
